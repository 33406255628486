import {
    Button,
    Grid,
    Paper,
    Typography,
} from '@material-ui/core';

import React, {useEffect, useState} from 'react';

import {withRouter} from "react-router-dom";
import {withStyles} from '@material-ui/core/styles';

import {FF1Colors} from "../../themes/global-colors";

import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Email';
import ChatIcon from '@material-ui/icons/Message';

const styles = theme => ({
    paper: {
        padding: theme.spacing(3),
        maxWidth: '960px',
        margin: '0 auto',
        marginBottom: theme.spacing(2),
    },
    contactOption: {
        minHeight: "100px",
    },
    optionEnabled: {
        marginBottom: 5,
    },
    optionDisabled: {
        marginBottom: 5,
        filter: "grayscale(1)",
        pointerEvents: "none",
        opacity: 0.5
    },
    buttonColumn: {
        flexGrow: 1,
    },
    buttonIcon: {
        marginRight: 10,
        background: FF1Colors.orange,
        color: "white",
        height: 40,
        width: 40,
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 6
    },
    contactButton: {
        width: "100%",
        maxWidth: "300px"
    }
});

const KisDocuments = props => {
    const {classes} = props;
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const updateIsOpen = () => {
            const hour = new Date().getHours();
            const day = new Date().getDay();

            if (hour >= 8 && hour < 20 && day > 0 && day < 6) {
                setIsOpen(true);
            } else {
                setIsOpen(false);
            }
        };

        // Set the class when the component mounts
        updateIsOpen();

        // Check once per minute
        const intervalId = setInterval(updateIsOpen, 60000);

        // Clean up on unmount
        return () => clearInterval(intervalId);
    }, []);

    // Get from storage or use default
    const getCaseEMail = () => {
        const caseDetails = JSON.parse(sessionStorage.getItem('caseDetails'));
        const caseID =  caseDetails ? caseDetails.id : false;

        if (caseID) {
            return "fall+" + caseID + "@faire-regulierung.de"
        }

        return "post@fairforce.one";
    }

    return (
        <React.Fragment>
            <Paper className={classes.paper}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant='h4'>Kontakt</Typography>
                    </Grid>

                    <Grid item xs={12} className={classes.contactOption}>
                        <Grid container className={isOpen ? classes.optionEnabled : classes.optionDisabled}>
                            <Grid item>
                                <PhoneIcon fontSize='small' className={classes.buttonIcon}/>
                            </Grid>
                            <Grid item className={classes.buttonColumn}>
                                <Button href="tel:0800 30 111 60" className={classes.contactButton}>0800 - 30 111
                                    60</Button>
                            </Grid>
                        </Grid>
                        <Typography paragraph>Wir sind Montag bis Freitag von 08:00 bis 20:00 Uhr für Sie
                            erreichbar</Typography>
                    </Grid>

                    <Grid item xs={12} className={classes.contactOption}>
                        <Grid container className={classes.optionEnabled}>
                            <Grid item>
                                <MailIcon fontSize='small' className={classes.buttonIcon}/>
                            </Grid>
                            <Grid item className={classes.buttonColumn}>
                                <Button href={"mailto:" + getCaseEMail()} className={classes.contactButton}>E-Mail
                                    senden</Button>
                            </Grid>
                        </Grid>
                        {false &&
                        <Typography paragraph>Senden Sie einfach eine E-Mail an <a href={"mailto:" + getCaseEMail()}>{getCaseEMail()}</a></Typography>
                        }
                    </Grid>

                    <Grid item xs={12} className={classes.contactOption}>
                        <Grid container className={classes.optionDisabled}>
                            <Grid item>
                                <ChatIcon fontSize='small' className={classes.buttonIcon}/>
                            </Grid>
                            <Grid item className={classes.buttonColumn}>
                                <Button href="#" className={classes.contactButton}>Chatten</Button>
                            </Grid>
                        </Grid>
                        <Typography paragraph>Coming soon!</Typography>

                    </Grid>
                </Grid>
            </Paper>
        </React.Fragment>
    );
}


export default withRouter(withStyles(styles)(KisDocuments));
