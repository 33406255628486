import React, {useState, useEffect} from 'react';
import {withRouter} from "react-router-dom";
import userPlaceHolder from "../../assets/images/user_placeholder.jpg";
import verifiedTick from "../../assets/images/verified_tick.svg";
import Tooltip from '@material-ui/core/Tooltip';

import {
    withStyles,
    Grid,
    FormControl,
    InputLabel,
    MenuItem,
    Typography,
    Select,
} from '@material-ui/core';

import iPhoneMockup from "../../assets/images/230108_ff1_mockup.png"
import trustBadge from "../../assets/images/250218_ff1_lizenz_siegel_single.svg"
import trustBadgeWide from "../../assets/images/250218_ff1_lizenz_siegel_single_wide.svg"

const styles = theme => ({
    type: {
        color: 'black',
        lineHeight: 1.3,
    },
    typeBold: {
        fontWeight: 700,
    },
    textCol: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'right',
    },
    iphoneMockup: {
        display: 'block',
        width: "100%",
        height: "450px",
        objectFit: "contain",
        [theme.breakpoints.down('xs')]: {
            paddingTop: 50,
            maxWidth: "45%",
            height: 'auto',
            display: 'none',
        },
    },
    selectContainer: {
        minWidth: 200,
        marginBottom: 10,
        [theme.breakpoints.down('sm')]: {
            minWidth: '90%',
        },
    },
    badgeColImg: {
        padding: 5,
    },
    badgeImg: {
        maxWidth: 50,
        display: 'block',
        borderRadius: '50%',
        marginLeft: 'auto',
    },
    badgeColText: {
        padding: 5,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    badgeName: {
        fontSize: 16,
        fontWeight: 700,
        textAlign: "left",
    },
    badgeBranch: {
        fontSize: 12,
        textAlign: "left",
    },
    badgeVerified: {
        display: 'inline-block',
        maxHeight: '0.9em',
        marginLeft: 5,
        verticalAlign: 'text-top'
    },
    trustBadge: {
        width: "100%",
        height: "auto",
        maxWidth: 180,
        [theme.breakpoints.down('xs')]: {
            margin: '0 auto',
        },
    },
    trustBadgeWide: {
        width: "100%",
        height: "auto",
        maxWidth: 250,
        margin: '0 auto',
        marginTop: "16px",
    },
});

const Greeting = (props) => {
    const {classes, employees, branches, badgeData, sourceId} = props;

    const [state, setState] = useState({
        employee: props.state.employee || '',
    });

    const [employeeOpen, setEmployeeEmployeeOpen] = React.useState(false)
    const [branchOpen, setBranchOpen] = React.useState(false)

    const validateForm = () => props.validationHandler(() => {
        return true
    });
    validateForm()

    const handleChange = (e) => {
        // Switch branch if possible
        if (e.target.name === "branch" && e.target.value !== sourceId) {

            const path = props.baseUrl.replace(sourceId, parseInt(e.target.value));
            props.history.push(path);
            window.location.reload()
        }

        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    }

    const handleEmployeeClose = () => {
        setEmployeeEmployeeOpen(false)
    };
    const handleEmployeeOpen = () => {
        setEmployeeEmployeeOpen(true)
    };

    const handleBranchClose = () => {
        setBranchOpen(false)
    };
    const handleBranchOpen = () => {
        setBranchOpen(true)
    };

    useEffect(() => {
        props.stateChange(state);
    }, [state.employee])

    return (
        <Grid container direction='row' justify='space-between'>
            <Grid item xs={12} sm={6} className={classes.textCol}>
                <Grid container align='center' justify='center' direction='column'>
                    <Typography variant='h5' className={classes.typeBold}>Jetzt Unfall melden</Typography>
                    <Typography paragraph>Bei Ihrem lizenzierten Unfallspezialisten.</Typography>

                    {badgeData && badgeData.employee &&
                        <Grid container align='start' justify='center' direction='row'>
                            <Grid item xs={4} className={classes.badgeColImg}>
                                <img src={userPlaceHolder} className={classes.badgeImg}
                                     alt={'Profilbild'}/>
                            </Grid>
                            <Grid item xs={8} className={classes.badgeColText}>
                                <Typography className={classes.badgeName}>{badgeData.employee.name}
                                    <Tooltip placement="top" title='Lizenzierter Unfallspezialist'>
                                        <img src={verifiedTick} className={classes.badgeVerified}
                                             alt={'Lizenzierter Unfallspezialist'}/>
                                    </Tooltip>
                                </Typography>
                                <Typography className={classes.badgeBranch}>{badgeData.branch.name}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} className={classes.badgeColImg}>
                            </Grid>
                            <Grid item xs={12} sm={8} className={classes.badgeColText}>
                                <img src={trustBadge} className={classes.trustBadge}
                                     alt={'lizenzierter Spezialist'}/>
                            </Grid>
                        </Grid>
                    }

                    {!(badgeData && badgeData.employee) &&
                        <Grid container align='center' justify='center' direction='row'>
                            {branches &&
                                <FormControl className={classes.selectContainer}>
                                    <InputLabel htmlFor="employees-select">Filiale</InputLabel>
                                    <Select
                                        id="branches-select"
                                        name="branch"
                                        open={branchOpen}
                                        onClose={handleBranchClose}
                                        onOpen={handleBranchOpen}
                                        value={sourceId}
                                        onChange={handleChange}
                                    >
                                        {branches.map(singleBranch => (<MenuItem key={singleBranch.id}
                                                                                 value={singleBranch.id}>{singleBranch.name}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            }
                            {employees &&
                                <FormControl className={classes.selectContainer}>
                                    <InputLabel htmlFor="employees-select">Ansprechpartner</InputLabel>
                                    <Select
                                        id="employees-select"
                                        name="employee"
                                        open={employeeOpen}
                                        onClose={handleEmployeeClose}
                                        onOpen={handleEmployeeOpen}
                                        value={state.employee}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="0"><em>Beliebig</em></MenuItem>
                                        {employees.map(singleEmployee => (<MenuItem key={singleEmployee.id}
                                                                                    value={singleEmployee.id}>{singleEmployee.name}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            }

                            <Grid item xs={12} sm={12} className={classes.badgeColText}>
                                <img src={trustBadgeWide} className={classes.trustBadgeWide}
                                     alt={'lizenzierter Spezialist'}/>
                            </Grid>

                        </Grid>
                    }
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Grid container justify='center'>
                    <img src={iPhoneMockup} className={classes.iphoneMockup}
                         alt={'iPhone mit Kundeninformationssystem'}/>
                </Grid>
            </Grid>
        </Grid>
    )
};

export default withRouter(withStyles(styles)(Greeting));