import React from 'react';
import {List, ListItem, withStyles, Grid, Typography, Hidden, Button} from "@material-ui/core";
import {FF1Colors} from "../../themes/global-colors";
import TrustBoxFull from "../Shared/TrustBoxFull";
import trustFF1 from "../../assets/images/trust_ff1.svg";

const styles = theme => ({
    type: {
        color: 'black',
        lineHeight: 1.3,
    },
    typeBold: {
        fontWeight: 700,
    },
    counterParent: {
        counterReset: 'list',
        paddingLeft: '2em',
    },
    counter: {
        counterIncrement: 'list',
        position: 'relative',

        "&::before": {
            content: `counter(list)`,
            position: 'absolute',
            top: '8px',
            left: '-15px',
            width: '20px',
            height: '20px',
            border: `1px solid ${FF1Colors.darkGray}`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
        }
    },
    trustContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: '1rem'
    },
    trustLogo: {
        minWidth: 150,
        maxWidth: 175,
        marginBottom: '0.5rem'
    },
    scrollTopButton: {
        display: 'block',
        width: '100%',
        maxWidth: 180,
        margin: '1rem auto',
        textAlign: 'center'
    }
});

const ProcessList = ({classes}) => {
    const scrollHandle = (e) => {
        e.preventDefault();
        let url = e.currentTarget.href
        url = url.split("#");
        url = url.pop();

        let target = document.getElementById(url)
        if (target) {
            target.scrollIntoView({
                behavior: 'smooth'
            });
        }
    }

    return (
        <Grid container justify='flex-start'>
            <Grid item>
                <Typography paragraph className={[classes.type, classes.typeBold].join(' ')} variant='h6' align='left'>Wir
                    sind
                    Ihr lizenzierter Partner für Schadensregulierung.</Typography>
                <List className={classes.counterParent}>
                    <ListItem className={classes.counter}>Hier mit wenigen Klicks Unfall melden</ListItem>
                    <ListItem className={classes.counter}>Unsere Unfallspezialisten melden sich telefonisch bei
                        Ihnen.* <br/>Sie besprechen alle Fragen und erhalten einen klaren Fahrplan über die für Sie
                        richtigen nächsten Schritte.
                    </ListItem>
                    <ListItem className={classes.counter}>Reparatur, Ersatzwagen, Erstattungen, Rechtsberatung…<br/>
                        Wir kümmern uns, dass Sie schnellstmöglich wieder mobil sind.
                    </ListItem>
                </List>

                <Hidden smUp>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <Button className={classes.scrollTopButton} onClick={scrollHandle} href='#melden'>Unfall
                                melden</Button>
                        </Grid>
                        <Grid item xs={12} sm={12} className={classes.trustContainer}>
                            <img src={trustFF1} className={classes.trustLogo} alt={'Fair Force One'}/>

                            <TrustBoxFull/>
                        </Grid>
                    </Grid>
                </Hidden>

                <p style={{textAlign: 'center'}}><small>*werktags von 08:00 bis 20:00 Uhr</small></p>
            </Grid>
        </Grid>
    )
};

export default withStyles(styles)(ProcessList);