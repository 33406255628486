import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {withRouter} from "react-router-dom";


const styles = theme => ({
    hideSmaller: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    hideLarger: {
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
    },
});

const TrustBox = (props) => {
    const { classes } = props;


    // Create separate references for each TrustBox div
    const refDesktop = React.useRef(null);
    const refMobile = React.useRef(null);

    React.useEffect(() => {
        if (window.Trustpilot) {
            if (refDesktop.current) {
                window.Trustpilot.loadFromElement(refDesktop.current, true);
            }
            if (refMobile.current) {
                window.Trustpilot.loadFromElement(refMobile.current, true);
            }
        }
    }, []);
    return (
        <React.Fragment>
            <div
                ref={refDesktop}
                className={[classes.hideSmaller, 'trustpilot-widget'].join(' ')}
                data-locale="de-DE"
                data-template-id="5419b732fbfb950b10de65e5"
                data-businessunit-id="62e24615a2e26dceb7ec70ab"
                data-style-height="50px"
                data-style-width="100%"
                data-theme="light">
                <a href="https://de.trustpilot.com/review/fairforce.one" target="_blank"
                   rel="noopener noreferrer">Trustpilot</a>
            </div>
            <div
                ref={refMobile}
                className={[classes.hideLarger, 'trustpilot-widget'].join(' ')}
                data-locale="de-DE"
                data-template-id="5419b757fa0340045cd0c938"
                data-businessunit-id="62e24615a2e26dceb7ec70ab"
                data-style-height="25px"
                data-style-width="100%"
                data-theme="light">
                <a href="https://de.trustpilot.com/review/fairforce.one" target="_blank"
                   rel="noopener noreferrer">Trustpilot</a>
            </div>
        </React.Fragment>
    )
        ;
};
export default withStyles(styles)(TrustBox);
