import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
});

const TrustBoxFull = (props) => {
    const { classes } = props;


    // Create separate references for each TrustBox div
    const ref = React.useRef(null);

    React.useEffect(() => {
        if (window.Trustpilot) {
            if (ref.current) {
                window.Trustpilot.loadFromElement(ref.current, true);
            }
        }
    }, []);
    return (
        <React.Fragment>
            <div
                ref={ref}
                className={['trustpilot-widget'].join(' ')}
                data-locale="de-DE"
                data-template-id="53aa8807dec7e10d38f59f32"
                data-businessunit-id="62e24615a2e26dceb7ec70ab"
                data-style-height="150px"
                data-style-width="190px"
                data-theme="light">
                <a href="https://de.trustpilot.com/review/fairforce.one" target="_blank"
                   rel="noopener noreferrer">Trustpilot</a>
            </div>
        </React.Fragment>
    )
        ;
};
export default withStyles(styles)(TrustBoxFull);
