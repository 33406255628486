import React from 'react';
import {Grid, withStyles} from "@material-ui/core";
import trustFF1 from "../../assets/images/trust_ff1_stacked.svg";
import trustGermany from "../../assets/images/trust_made_in_germany.svg";
import TrustBox from "../Shared/TrustBox";

const styles = theme => ({
    logosContainer: {
        justifyContent: "flex-end",
        alignItems: "center",
    },
    logoContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 5,
    },
    logosImgPowered: {
        display: 'block',
        maxHeight: 55,
        maxWidth: '100%',
        width: "100%",
        objectFit: 'contain',
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            maxHeight: 40,
        },
    },
    logosImgHosted: {
        display: 'block',
        maxHeight: 40,
        maxWidth: '100%',
        width: "100%",
        objectFit: 'contain',
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            maxHeight: 30,
        },
    },
})

const TrustLogos = (props) => {
    const {classes} = props;

    return (
        <Grid container className={classes.logosContainer}>
            <Grid item xs={6} sm={3} className={classes.logoContainer}>
                <img src={trustGermany} className={classes.logosImgHosted}
                     alt={'Made and hosted in Germany'}/>
            </Grid>
            <Grid item xs={6} sm={3} className={classes.logoContainer}>
                <img src={trustFF1} className={classes.logosImgPowered} alt={'Fair Force One'}/>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.logoContainer}>
                <TrustBox/>
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(TrustLogos);
;